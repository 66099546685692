<template>
  <div>
    <v-progress-circular :size="50"
                         color="primary"
                         indeterminate>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "LoadingImage"
}
</script>

<style scoped>

</style>
